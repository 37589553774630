<template>
    <b-form style="margin-bottom: 9px" @submit.prevent="onSubmit">
        <!-- File -->
        <div v-if="isImage" class="image-container">
            <div v-if="newImageAsBase64 !== null">
                <label>Vald</label>
                <img :src="newImageAsBase64" />
            </div>
            <div v-else-if="form.id > 0">
                <label>Vald</label>
                <DownloadFileButton size="sm"
                                    :fileId="form.id" />
                <img :src="imgUrl" />
            </div>
        </div>

        <div v-else-if="form.id > 0 && !isImage">
            <DownloadFileButton size="sm"
                                :fileId="form.id" />
        </div>

        <b-form-group :id="`input-group-file-uploader-${_uid}`"
                      :label="!form.id ? 'Ladda upp' : 'Ersätt'"
                      :label-for="`input-file-uploader-${_uid}`">
            <b-form-file :id="`input-file-uploader-${_uid}`"
                         v-model="form.file"
                         drop-placeholder="Släpp här"
                         :accept="allowedExtensions"
                         placeholder="Klicka eller dra en fil hit"
                         :disabled="isSubmitting"
                         @change="onImageChanged"
                        :aria-describedby="`input-description-help-block-${_uid}`" />
            <b-form-text :id="`input-file-uploader-help-block-${_uid}`">
                <ul class="text-danger mb-0">
                    <li v-if="form.file === null">Du måste välja en fil</li>
                    <li v-for="message in validationByKey('File')" :key="message">
                        {{ message }}
                    </li>
                </ul>
            </b-form-text>
        </b-form-group>

        <!-- Description -->
        <b-form-group :id="`input-group-description-${_uid}`"
                      label="Beskrivning"
                      :label-for="`input-description-${_uid}`">
            <b-form-textarea :id="`input-description-${_uid}`"
                             v-model="form.description"
                             type="text"
                             placeholder="Ange beskrivning"
                             :disabled="isSubmitting"
                             autocomplete="off"
                             rows="3"
                             :aria-describedby="`input-description-help-block-${_uid}`" />
            <b-form-text :id="`input-description-help-block-${_uid}`">
                <ul class="text-danger mb-0">
                    <li v-for="message in validationByKey('Description')"
                        :key="message">
                        {{ message }}
                    </li>
                </ul>
            </b-form-text>
        </b-form-group>

        <b-alert :show="validationByKey('Other').length > 0" variant="danger">
            <ul class="text-danger mb-0">
                <li v-for="message in validationByKey('Other')" :key="message">
                    {{ message }}
                </li>
            </ul>
        </b-alert>

        <b-alert :show="successMessage.length > 0"
                 variant="success"
                 class="mb-0 mt-4">
            <h6 class="mb-0 text-center">
                <font-awesome-icon icon="info-circle" /> {{ successMessage }}
            </h6>
        </b-alert>

        <b-button v-b-tooltip.hover
                  variant="primary"
                  type="submit"
                  block
                  class="mt-4"
                  :disabled="isSubmitting"
                  :title="isSubmitting ? 'Sparar...' : ''">
            <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Spara
        </b-button>
    </b-form>
</template>

<script>
    import { post, get } from '@/helpers/api';
    import { convertToBase64, isImageContentType } from '@/helpers/file.helper';
    import { getEntityTypeById } from '@/helpers/entity-type.helper';
    import DownloadFileButton from '@/components/file/DownloadFileButton';

    export default {
        name: 'ManageFile',
        props: {
            id: Number,
            entityType: Number,
            entityId: Number,
            fileType: {
                type: Number,
                required: false
            }
        },
        components: {
            DownloadFileButton
        },
        data() {
            return {
                newImageAsBase64: null,
                allowedExtensions: getEntityTypeById(this.entityType).allowedExtensions.toString(),
                isSubmitting: false,
                successMessage: '',
                validationErrors: null,
                form: {
                    id: 0,
                    description: '',
                    file: null,
                    fileContentType: null,
                    fileType: this.fileType || 0
                }
            };
        },
        computed: {
            isEditMode() {
                return this.form.id > 0;
            },
            isImage() {
                return (
                    this.form.file !== null && isImageContentType(this.form.fileContentType || this.form.file.type)
                );
            },
            imgUrl() {
                return `${process.env.VUE_APP_ROOT_API}File/Download/${this.form.id}?r=${Math.random()}`;
            }
        },
        async mounted() {
            this.form.id = this.id

            if (this.isEditMode)
                await this.getData(this.form.id);
        },
        methods: {
            async getData(id) {
                await get('File', `GetById/${id}`)
                    .then((x) => {
                        this.form = x.data;
                    })
                    .catch((x) => {
                        alert(x.data);
                    });
            },
            downloadFile() {
                window.open(
                    `${process.env.VUE_APP_ROOT_API}File/Download/${this.form.id}`,
                    '_blank',
                    ''
                );
            },
            async onImageChanged(event) {
                if (!event.path[0].files[0])
                    return;

                this.newImageAsBase64 = await convertToBase64(event.path[0].files[0]);
            },
            reset() {
                this.successMessage = '';
                this.validationErrors = null;
            },
            async onSubmit() {
                this.isSubmitting = true;

                // Preparing form.
                let data = new FormData();
                data.append('fileId', this.form.id);
                data.append('entityType', this.entityType);
                data.append('fileType', this.form.fileType);
                data.append('description', this.form.description);

                if (this.entityId)
                    data.append('entityId', this.entityId);

                if (this.form.file)
                    data.append('file', this.form.file);

                // Post.
                await post('File', 'SaveFromListView', data)
                    .then((x) => {
                        this.isSubmitting = false;
                        this.$emit('update:changed-edit-mode', this.form);
                        this.$emit('update:updateData', true);
                        //this.newImageAsBase64 = null;
                        //this.getData(this.form.id);
                    })
                    .catch((x) => {
                        this.validationErrors = x.response.data.errors;
                        this.isSubmitting = false;
                    });
            },
            resetForm() {
                this.form = {
                    id: this.id || 0,
                    description: '',
                    file: null,
                    fileType: this.fileType || 0,
                    fileContentType: null
                };
            },
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            }
        }
    };
</script>

<style scoped>
    .form-group {
        margin-bottom: 0.5rem;
    }

    .image-container img {
        max-height: 250px;
    }
</style>

<style>
    .custom-file-input ~ .custom-file-label[data-browse]::after {
        content: 'Välj filer';
    }
</style>
