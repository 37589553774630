<template>
    <b-button variant="light"
              class="float-right"
              :size="size"
              @click="download()">
        Ladda ned
    </b-button>
</template>

<script>
    export default {
        name: "DonwloadFileButton",
        props: {
            fileId: Number,
            size: {
                type: String,
                default: '',
                required: false
            }
        },
        methods: {
            download() {
                window.open(
                    `${process.env.VUE_APP_ROOT_API}File/Download/${this.fileId}`,
                    '_blank',
                    ''
                );
            }
        }
    };
</script>
