<template>
  <b-sidebar
    backdrop-variant="dark"
    backdrop
    right
    lazy
    :width="getWidth()"
    shadow
    :visible="visible"
    @hidden="onHidden"
  >
    <template v-slot:title>
      <slot name="header">
        <h4 class="text-center">Mer information</h4>
      </slot>
    </template>
    <div class="px-2">
      <div v-if="item && !isEditMode" class="card-body">
        <div class="card-text">
          <dl v-for="field in fields" :key="field.id" class="row">
            <dt class="col-12">
              {{ field.label }}
            </dt>
            <dd class="col-12" style="word-break: break-all">
              {{ getValue(field, item[field.key]) }}
            </dd>
          </dl>
        </div>
      </div>

      <slot name="edit" />
      <div v-if="!isEditMode">
        <b-button variant="primary" block @click="close"> Stäng </b-button>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
export default {
  props: {
    item: Object,
    fields: Array,
    visible: Boolean,
    isEditMode: Boolean,
    width: {
      type: String,
      default: '320px'
    },
    widthWhenEditing: {
        type: String,
        required: false,
        default: '320px'
    }
  },
  methods: {
      onHidden() {
          if (this.isEditMode) {
              this.$emit('update:updateData', true);

          }
      this.close();
    },
    close() {
      this.$emit('update:isEditMode', false);
      this.$emit('update:visible', false);
    },
    getWidth() {
      if (this.isEditMode && this.widthWhenEditing)
        return this.widthWhenEditing;

      return this.width;
    },
    getValue(field, value) {
      if (field.filter) {
        let filteredLabel = this.$options.filters.datetime(
          value,
          field.filter.value
        );
        return filteredLabel;
      }

      return value;
    }
  }
};
</script>

<style>
/*Editor fullscreen fix*/
.tox-fullscreen .b-sidebar {
  width: 100% !important;
}
</style>
